<script setup>
import NavigationNew from "@/components/NavigationNew.vue";
import store from "@/store";
import { random } from "lodash-es";
import { onMounted, ref, computed, reactive, watch } from "vue";
import { DateTime } from "luxon";
import TermsAcceptModal from "../components/TermsAcceptModal.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Fixed from "@/components/Fixed.vue";
import Overlay from "@/components/Overlay.vue";
import Modal from "@/components/Modal.vue";

const forceVerification = ref(false);

const portalNum = ref(0);
onMounted(() => {
  portalNum.value = random(0, 3, false);
  loadPortalInfo();
});

const loading = ref(false);

const image = computed(() => {
  let arrayImages = [
    require("@/assets/backgrounds/portal-v1.jpg"),
    require("@/assets/backgrounds/portal-v2.jpg"),
    require("@/assets/backgrounds/portal-v3.jpg"),
    require("@/assets/backgrounds/portal-v4.jpg"),
  ];

  return arrayImages[portalNum.value];
});

const user = computed(() => {
  return store.state.auth.user;
});

const formatNumber = (num) => {
  return num.toLocaleString("en-US");
};

const formatDate = (date) => {
  return date ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED) : null;
};

const subtractSecondAndFormat = (date) => {
  if (!date) {
    return null;
  }

  return `${DateTime.fromISO(date, "UTC")
    .minus({ minutes: 1 })
    .toLocaleString(DateTime.DATETIME_MED)} (local time)`;
};

const portalRedemptionCredit = async () => {
  loading.value = true;
  let req = await store.dispatch("arcade/portalRedemptionCredit", {
    recipient: redeemForm.recipient,
    quantity: redeemForm.quantity,
  });
  loadPortalInfo();
  if (!req) {
    loading.value = false;
    return;
  }
  await store.dispatch("auth/getUser");
  redeemForm.quantity = 1;
  loading.value = false;
};

let portalInfo = ref(null);

const loadPortalInfo = async () => {
  loading.value = true;
  portalInfo.value = await store.dispatch("arcade/portalInfo");
  loading.value = false;
};

const redeemForm = reactive({
  recipient: null,
  quantity: 1,
});

watch(user, (newVal, oldVal) => {
  if (!oldVal && newVal) {
    // redeemForm.recipient = user.value?.wallets?.[0]?.wallet_address ?? null;
  }
});

const maxQuantity = computed(() => {
  if (!user.value) {
    return 1;
  }

  return Math.floor(user.value?.credits?.total / user.value?.credits?.token_credit_cost) ?? 1;
});

const currentExpedition = computed(() => {
  return portalInfo.value ? portalInfo.value?.find((e) => e.is_current) : null;
});

const nextExpedition = computed(() => {
  if (!portalInfo.value) {
    return null;
  }

  let copyPortalInfo = [...portalInfo.value].sort((a, b) => a.order - b.order);

  console.log({ copyPortalInfo });

  return portalInfo.value
    ? [...portalInfo.value]
        ?.sort((a, b) => a.order - b.order)
        ?.find((e) => e.order === currentExpedition?.value.order + 1)
    : null;
});

const combineRedemptions = (arrayOfOrders) => {
  if (!arrayOfOrders) {
    return [];
  }

  let combinedOrders = {};
  arrayOfOrders.forEach((order) => {
    if (combinedOrders?.[order.username]?.quantity) {
      combinedOrders[order.username].quantity =
        combinedOrders[order.username].quantity + order.quantity;
    } else {
      combinedOrders[order.username] = {
        username: order.username,
        quantity: order.quantity,
      };
    }
  });

  return Object.keys(combinedOrders)
    .map((o) => combinedOrders[o])
    .sort((a, b) => b.quantity - a.quantity);
};
const combinedRedemptions = computed(() => {
  if (currentExpedition.value?.orders) {
    return combineRedemptions(currentExpedition?.value?.orders);
    // let combinedOrders = {};
    // currentExpedition.value?.orders?.forEach((order) => {
    //   if (combinedOrders?.[order.username]?.quantity) {
    //     combinedOrders[order.username].quantity =
    //       combinedOrders[order.username].quantity + order.quantity;
    //   } else {
    //     combinedOrders[order.username] = {
    //       username: order.username,
    //       quantity: order.quantity,
    //     };
    //   }
    // });

    // return Object.keys(combinedOrders)
    //   .map((o) => combinedOrders[o])
    //   .sort((a, b) => b.quantity - a.quantity);
  }

  return [];
});

const combinedNextRedemptions = computed(() => {
  if (nextExpedition.value?.orders) {
    return combineRedemptions(nextExpedition?.value?.orders);
    // let combinedOrders = {};
    // currentExpedition.value?.orders?.forEach((order) => {
    //   if (combinedOrders?.[order.username]?.quantity) {
    //     combinedOrders[order.username].quantity =
    //       combinedOrders[order.username].quantity + order.quantity;
    //   } else {
    //     combinedOrders[order.username] = {
    //       username: order.username,
    //       quantity: order.quantity,
    //     };
    //   }
    // });

    // return Object.keys(combinedOrders)
    //   .map((o) => combinedOrders[o])
    //   .sort((a, b) => b.quantity - a.quantity);
  }

  return [];
});

const showIntro = ref(false);

const showAbout = ref(false);
</script>

<template>
  <div
    class="h-full bg-cover bg-center relative"
    :style="{
      'background-image': `url('${image}')`,
    }"
  >
    <NavigationNew mode="light" />

    <Breadcrumbs
      :links="[
        { routeName: 'Home', label: 'Home' },
        { routeName: 'Arcade', label: 'Arcade' },
        { label: 'Portal Expeditions' },
      ]"
    />

    <div class="container">
      <div
        class="flex items-center justify-center cursor-pointer"
        @click="() => (showAbout = true)"
      >
        <div class="mr-4 flex-shrink-0">
          <img :src="require('@/assets/home/nigel.png')" class="h-24" />
        </div>
        <div
          class="mb-4 box-shadow-std border-ch1blue p-4 bg-white rounded-xl text-center font-brother font-bold text-ch1blue"
        >
          New to the Society? Click here to learn how portal expeditions work!
        </div>
      </div>
    </div>

    <div class="flex-grow flex items-center justify-center w-full relative z-20">
      <div class="container">
        <div class="flex flex-col md:flex-row items-start flex-wrap">
          <div class="w-full md:w-1/2 self-stretch mb-4">
            <div class="p-8 rounded-xl bg-ch1cream-light box-shadow-std h-full mb-4">
              <h3 class="font-base text-gray-700 font-brother mb-2 uppercase text-sm md:w-1/2">
                Next Group Details
              </h3>
              <h1 class="font-brother font-black mb-4 text-4xl">
                Expedition Group {{ currentExpedition?.name }}
              </h1>
              <div class="mb-2 italic text-sm">
                Target embarkation: {{ formatDate(currentExpedition?.target) }}
              </div>
              <div class="mb-4 italic text-sm">
                Funding cutoff: {{ subtractSecondAndFormat(currentExpedition?.cutoff) }}
              </div>
              <div class="mb-4 font-bold" v-if="currentExpedition?.orders?.length">
                Currently funded expeditions:
              </div>
              <div
                v-for="(expedition, e) in combinedRedemptions"
                :key="e"
                class="px-2 py-1 mb-2 border border-gray-200 rounded bg-ch1cream-light truncate"
              >
                <span class="font-bold mr-2">{{ expedition.quantity }}</span
                >{{ expedition.username }}
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/2 pl-0 md:pl-4">
            <div class="rounded-xl p-8 bg-ch1cream-light box-shadow-std mb-4">
              <div
                class="mb-4 overflow-hidden rounded-lg cursor-pointer"
                @click="() => (showIntro = true)"
              >
                <img :src="require('@/assets/backgrounds/notice-v1-crop.jpg')" />
              </div>
              <div class="mb-4">
                <h2 class="font-black text-ch2blue-light font-brother">Fund Portal Expeditions</h2>
              </div>

              <div class="mb-4 font-maindisplay">
                You can fund as many expeditions as you can afford, with each expedition bringing
                back a single Citizen, Competitor, or Steward that has been lost.
              </div>
              <div v-if="user">
                <div class="mb-4 font-maindisplay">
                  Each expedition costs
                  <strong>{{ formatNumber(user.credits.token_credit_cost) }} credits</strong>
                </div>
                <div v-if="user?.credits?.total >= user.credits.token_credit_cost">
                  <div class="text-base mb-4 font-bold text-green-500">
                    You have {{ formatNumber(user.credits.total) }} credits
                  </div>
                  <div class="font-bold mb-4 text-lg">How many expeditions will you fund?</div>
                  <div class="flex flex-col mb-4">
                    <label class="uppercase text-gray-400 font-bold text-xs mb-2">Quantity </label>
                    <select
                      v-model="redeemForm.quantity"
                      class="px-3 py-2 border border-gray-200 bg-white rounded"
                    >
                      <option v-for="val in maxQuantity" :key="val" :value="val">{{ val }}</option>
                    </select>
                  </div>
                  <div class="flex flex-col mb-4">
                    <label class="uppercase text-gray-400 font-bold text-xs mb-2">
                      Recipient Wallet
                    </label>
                    <select
                      v-model="redeemForm.recipient"
                      class="px-3 py-2 border border-gray-200 bg-white rounded"
                    >
                      <option :value="null">Select a wallet</option>
                      <template v-if="user.user_verified_at || !forceVerification">
                        <option
                          v-for="(wallet, w) in user.wallets"
                          :key="w"
                          :value="wallet.wallet_address"
                        >
                          {{ wallet.username }}
                        </option>
                      </template>
                      <option
                        v-for="(wallet, w) in user?.custodial_wallets"
                        :key="w"
                        :value="wallet.wallet_address"
                      >
                        {{ wallet.username }} (Society Custodial)
                      </option>
                    </select>
                  </div>
                  <template v-if="!user.user_verified_at && forceVerification">
                    <div class="mb-2 text-red-400 font-bold text-sm">
                      In order to send to a self-custody wallet, you must
                      <router-link :to="{ name: 'Profile' }" class="underline">
                        verify your account
                      </router-link>
                    </div>
                  </template>
                  <div class="mb-4">
                    <button @click="portalRedemptionCredit" class="btn">
                      Pay
                      {{ formatNumber(redeemForm.quantity * user.credits.token_credit_cost) }}
                      credits
                    </button>
                  </div>
                </div>
                <div v-else>
                  <div class="mb-4 font-maindisplay font-bold text-ch1red">
                    You don't have enough credits to fund any expeditions (you currently have
                    {{ formatNumber(user.credits.total) }} credits).
                  </div>
                  <div
                    class="bg-blue bg-opacity-80 p-4 rounded-lg text-white flex flex-col items-center justify-center"
                  >
                    <div class="mb-2">You can buy portal passes with a credit card!</div>
                    <div class="flex">
                      <router-link
                        :to="{ name: 'BuyCredit' }"
                        class="btn btn-green font-bold font-brother"
                      >
                        Buy now
                      </router-link>
                    </div>
                  </div>
                  <!-- <div class="mb-2 text-sm italic text-gray-500">
                    You currently have {{ user.credits.total }} credits.
                    <router-link :to="{ name: 'Arcade' }" class="text-sm">
                      Try playing some of our games to earn more credits -
                      <span class="underline text-ch1blue">click here</span>
                    </router-link>
                  </div> -->
                </div>
              </div>
              <div v-else>
                <div class="mb-4 font-maindisplay">You must log in to fund an expedition.</div>
                <div class="flex mb-4">
                  <router-link
                    :to="{ name: 'Login', query: { from: encodeURI('/arcade/portal') } }"
                    class="btn btn-sm"
                  >
                    Login
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div
            class="p-8 rounded-xl bg-ch1cream-light ml-0 md:mr-4 mb-4 box-shadow-std w-full md:w-1/2"
          >
            <h3 class="font-base text-gray-700 font-brother mb-2 uppercase text-sm">
              Future Group Details
            </h3>
            <h1 class="font-brother font-black mb-4 text-2xl">
              Expedition Group {{ nextExpedition?.name }}
            </h1>
            <div class="mb-2 italic text-sm">
              Target embarkation: {{ formatDate(nextExpedition?.target) }}
            </div>
            <div class="mb-4 italic text-sm">
              Funding cutoff: {{ subtractSecondAndFormat(nextExpedition?.cutoff) }}
            </div>
            <div class="mb-4 font-bold" v-if="nextExpedition?.orders?.length">
              Currently funded expeditions:
            </div>
            <div
              v-for="(expedition, e) in combinedNextRedemptions"
              :key="e"
              class="px-2 py-1 mb-2 border border-gray-200 rounded bg-ch1cream-light truncate"
            >
              <span class="font-bold mr-2">{{ expedition.quantity }}</span
              >{{ expedition.username }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container pb-32"></div>
  </div>
  <TermsAcceptModal />
  <Fixed v-if="showIntro">
    <Overlay @click="() => (showIntro = false)">
      <Modal>
        <div class="mb-4 rounded-lg overflow-hidden max-w-lg mx-auto">
          <img :src="require('@/assets/backgrounds/portal-v5-min.jpg')" />
        </div>
        <h1 class="font-brother mb-4 font-black text-center">Help save someone lost in time!</h1>
        <p>
          You've always been fascinated by the mysteries of the universe, the unsolved puzzles that
          lie hidden beneath the fabric of time and space. It is this insatiable curiosity that
          leads you to the doorstep of the Society of the Hourglass, a secret organization that has
          long guarded the integrity of the space-time continuum. Their mission is to prevent time
          meddling and to maintain the balance between past, present, and future. As a member, you
          could travel to far-off times and places, such as the American Wild West, Ancient Rome,
          Feudal Japan, or even Mars Base 7. The allure of these adventures is almost irresistible.
        </p>

        <p>
          Lately, the Society faces a grave challenge. An enigmatic group, veiled in secrecy, has
          been opening time portals and abducting unsuspecting individuals from various time periods
          and locations. These victims are then stranded in unfamiliar destinations, causing
          disruptions in the delicate fabric of time. The Society has embarked on a mission to track
          down these lost souls and restore them to their rightful place in history.
        </p>

        <p>
          The Society of the Hourglass, while powerful, cannot carry out these vital recovery
          missions without assistance. They require the support of individuals like you, those who
          are willing to sponsor expeditions and help maintain the balance of time. Your sponsorship
          can make all the difference in the world and beyond. It can provide the resources needed
          to save those who have been taken and prevent the unraveling of the space-time continuum.
        </p>

        <p>
          As you ponder the opportunity to sponsor an expedition, you cannot help but feel a sense
          of responsibility for those who have been displaced by the malevolent actions of this
          shadowy organization. By lending your support, you can play a crucial role in ensuring the
          safety and stability of the universe.
        </p>

        <p>
          The question now is: will you take up the mantle of guardian, standing beside the Society
          of the Hourglass in their quest to maintain the delicate balance of time? The choice is
          yours, and the fate of countless lives hangs in the balance.
        </p>

        <!-- <div class="flex items-center justify-center mt-8">
          <button @click="() => (showAbout = true)" class="btn btn-blue">Proceed</button>
        </div> -->
      </Modal>
    </Overlay>
  </Fixed>
  <Fixed v-if="showAbout">
    <Overlay @click="() => (showAbout = false)">
      <Modal>
        <div class="text-ch1blue max-w-lg">
          <div class="text-center mb-8 font-bold font-brother">
            Portal Expeditions are the way you redeem points you've earned in the
            <router-link :to="{ name: 'Arcade' }" class="underline text-ch1blue"
              >Arcade</router-link
            >
            for new characters from our collection!
          </div>

          <div class="font-brother font-black text-2xl text-center mb-6 text-ch1blue">
            It's as simple as 1, 2, 3:
          </div>

          <div class="mb-4">
            <span class="font-brother font-black text-lg text-green-500"
              >1. Play games and earn</span
            >
            from each era. With a simple button click in the arcade you can convert your winnings
            for each game to credits.
          </div>

          <div class="mb-4">
            <span class="font-brother font-black text-lg text-green-500"
              >2. Redeem your credits</span
            >
            for portal expedition passes. You can select the wallet where you want to receive your
            characters. (You will always at least have a Society of the Hourglass custodial wallet
            available.)
          </div>

          <div class="mb-4">
            <span class="font-brother font-black text-lg text-green-500"
              >3. Expeditions launch</span
            >
            once a month. Gas fees on the Ethereum network can vary and we wait until conditions are
            favorable before we start recovering characters. Embarkation dates listed on the website
            can shift but we do our best to hit those dates (or earlier if we can).
          </div>

          <div class="flex items-center justify-center">
            <button @click="() => (showAbout = false)" class="btn btn-blue">Got it!</button>
          </div>
        </div>
      </Modal>
    </Overlay>
  </Fixed>
</template>
